import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  background: #d4bbfc;
  color: #4059ad;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 10vh;
  font-size: calc(1rem + 1vmin);
`;

const Footer = () => {
  return <FooterContainer>&copy; {new Date().getFullYear()}</FooterContainer>;
};

export default Footer;
