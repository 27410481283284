import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  background: #d4bbfc;
  color: #4059ad;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 10vh;
`;

const HeaderTitle = styled.h2`
  font-size: calc(2rem + 2vmin);
  font-family: 'Satisfy', cursive;
  font-style: normal;
`;

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderTitle>Ella</HeaderTitle>
    </HeaderContainer>
  );
};

export default Header;
