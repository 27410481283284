import React from 'react';
import styled from 'styled-components';
import './App.css';
import Header from './components/header/header';
import Footer from './components/footer/footer';

const CenterContainer = styled.div`
  background: radial-gradient(closest-side, #9667e0, #d4bbfc);
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AboutContainer = styled.div`
  background: rgba(226, 112, 146, 0.4);
  width: 90vw;
  height: 80vh;
  border-radius: calc(1rem + 1vmin);
`;

const TextContainer = styled.div`
  color: #f8f9fb;
  text-align: left;
  width: 100%;
  margin: 3vh 3vw;
  font-size: calc(1rem + 1.5vmin);
  line-height: 3rem;
`;

function App() {
  return (
    <div className="App">
      <Header />
      <CenterContainer>
        <AboutContainer>
          <TextContainer>
            Welcome to Ella
            <br />
            We believe in the best quality products for all.
          </TextContainer>
        </AboutContainer>
      </CenterContainer>
      <Footer />
    </div>
  );
}

export default App;
